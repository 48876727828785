import React, { useState } from "react"
import axios from "axios"
import { useForm, Controller, SubmitHandler } from "react-hook-form"

// UI
import SEO from "~components/seo"
import Header from "~components/Header/Header"
import Headline from "~ui/Headline"
import Button from "~ui/Button"
import Input from "~ui/Input"
import Textarea from "~ui/Textarea"
import SectionFooter from "~components/SectionFooter"

const Form: React.FC = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const {
    control,
    formState: { isSubmitting },
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    axios
      .post("https://demo-api.us.canopyservicing.com/marketing/contact", data)
      .then(() => {
        reset()
        setSubmitSuccess(true)
        window.scrollTo(0, 0)
      })
      .catch((error) => {
        // Automatically reports to Datadog
        console.error("Error submitting access request:", error)
        setSubmitError(true)
        window.scrollTo(0, 0)
      })
  }

  return (
    <>
      {submitSuccess && <SuccessMessage />}
      {submitError && <ErrorMessage />}
      <form onSubmit={handleSubmit(onSubmit)} className="pt-3" data-sal="slide-up" data-sal-delay="400">
        <div className="mb-4">
          <Controller
            name="fullName"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input title="Full Name" {...field} ref={null} disabled={isSubmitting} errors={errors.fullName} />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="emailAddress"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Input
                title="Email Address"
                type="email"
                {...field}
                ref={null}
                disabled={isSubmitting}
                errors={errors.emailAddress}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="message"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Textarea title="Message" {...field} ref={null} disabled={isSubmitting} errors={errors.message} />
            )}
          />
        </div>
        <Button
          type="submit"
          style="primary"
          size="large"
          value="Submit"
          disabled={isSubmitting}
          className="md:col-span-2 mt-2 w-full"
        />
      </form>
    </>
  )
}

const SuccessMessage = () => (
  <div className="bg-green-100 dark:bg-green-900 border border-green-200 dark:border-green-700 px-4 py-3 text-black dark:text-white rounded-xl mb-4">
    <strong>Thank you for your interest in Canopy!</strong> We have received your inquiry. Our customer success team
    will be in touch soon, typically within 24 hours.
  </div>
)

const ErrorMessage = () => (
  <div className="bg-red-100 dark:bg-red-900 border border-red-200 dark:border-red-800 px-4 py-3 text-black dark:text-white rounded-xl mb-4">
    <strong>Submit Error:</strong> We experienced an issue submitting this form. Please try again a little later.
  </div>
)

const ContactPage: React.FC = () => {
  return (
    <>
      <SEO
        canonicalTag="https://canopyservicing.com/contact/"
        description="Use the form on this page to contact us with questions about Canopy Servicing."
        title="Contact Us | Canopy Servicing"
      />
      <Header />
      <div className="md:min-h-screen">
        <div className="md:min-h-screen flex flex-col justify-center items-center justify-center px-6">
          <div className="max-w-md pt-12 pb-16 md:pb-24">
            <Headline level={4} className="text-3xl md:text-5xl mb-4" data-sal="slide-up" data-sal-delay="200">
              Contact Us
            </Headline>
            <p className="mb-6" data-sal="slide-up" data-sal-delay="300">
              Have a question about Canopy? Send us a message and we’ll get back to you as soon as possible.
            </p>
            <Form />
          </div>
        </div>
      </div>
      <SectionFooter />
    </>
  )
}

interface Inputs {
  fullName: string
  emailAddress: string
  message: string
}

export default ContactPage
