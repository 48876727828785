import React from "react"
import { FieldError } from "react-hook-form"

const Textarea: React.FC<TextareaProps> = ({
  errors,
  disabled,
  name,
  title,
  ref,
  rows = 4,
  ...props
}: TextareaProps) => {
  return (
    <div>
      {title && (
        <label htmlFor={name} className="block text-sm text-black dark:text-white font-semibold mb-2">
          {title}
          {errors && <span className="text-red-500 ml-2 font-normal">Required</span>}
        </label>
      )}
      <textarea
        className={`px-3 py-3 placeholder-gray-300 text-black dark:text-white relative bg-gray-50 dark:bg-gray-800 rounded-lg text-sm border outline-none focus:outline-none focus:ring focus:border-blue-400 dark:focus:border-blue-500 disabled:bg-gray-200 dark:disabled:bg-gray-700 w-full transition duration-200 ${
          errors ? "border-red-300 dark:border-red-800" : "border-gray-300 dark:border-gray-700"
        }`}
        id={name}
        rows={rows}
        ref={ref}
        disabled={disabled}
        {...props}
      />
    </div>
  )
}

interface TextareaProps {
  errors?: FieldError
  disabled?: boolean
  name: string
  ref?: string
  rows?: number
  title?: string
}

export default Textarea
